import Image from 'next/image'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import { ProductCardButton } from '@components/Home/ProductCardButton/ProductCardButton'
import { Domain } from '@enums/Domain'
import { IBannerProps } from '@interfaces/landingWidget'
import styles from './productbanner.module.scss'

export interface IProductBannerProps {
  data: IBannerProps
}

const AckoDriveProductBanner = ({ data }: IProductBannerProps) => (
  <div id={HomeComponentType.ProductBanner}>
    <div className={styles.ackoDriveProductContainer}>
      <span className={styles.ackoDriveProductHeading}>{data?.heading}</span>
      <div className={styles.ackoDriveProductSubHeading}>
        <span className={styles.subHeadingText}>{data?.subHeading}&nbsp;</span>
        <Image src={data?.logoSrc} width={90} height={28} alt="product logo" className={styles.logo} />
      </div>
      <div className={styles.ackoDriveProductWrapper}>
        {data?.productCards?.data?.map(productItem => (
          <ProductCardButton
            key={productItem?.id}
            product={productItem?.attributes}
            imageSize={136}
            domain={Domain.ACKODRIVE}
          />
        ))}
      </div>
      <div className={styles.disclaimer}>{data?.productsDisclaimer}</div>
    </div>
  </div>
)

export default AckoDriveProductBanner
