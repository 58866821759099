import { Domain } from '@enums/Domain'
import { getUrlWithQueryParams } from '@utils/queryParamUtils'
import { getConfig } from './evnConfig'

export const domainConfig = {
    [Domain.ACKO]: {
        events: {
            homePage: 'btn_explore_insurance',
        },
        style: () => '',
        onClick: url => (window.location.href = getUrlWithQueryParams(url)),
    },
    [Domain.ACKODRIVE]: {
        events: {
            homePage: 'track_event_complete',
        },
        style: styles => styles.isDrive,
        onClick: (url, env) => (window.location.href = getUrlWithQueryParams(`${getConfig(env).ackoDriveBaseUrl}${url}`)),
    },
}
