import OverlayModal from '@components/OverlayModal/overlayModal'
import { ModalType } from '@enums/ModalType'
import Image from 'next/image'
import { MODAL_CROSS_ICON } from '@constants/homepageLogos'
import { ModalItem } from '@interfaces/landingWidget'
import styles from './ProductModal.module.scss'

interface ProductModalProps {
  modalData: ModalItem
  show: boolean
  setShow: Function
}

export const ProductModal = ({ modalData, show, setShow }: ProductModalProps) => (
  <OverlayModal show={show} setShow={setShow} modalType={ModalType.LARGE} crossIconSrc={MODAL_CROSS_ICON}>
    <div className={styles.modalWrapper}>
      <div className={styles.contentWrapper}>
        <pre className={styles.modalHeading}>{modalData?.heading}</pre>
        <span className={styles.modalSubHeading}>{modalData?.subHeading}</span>
        <div className={styles.modalCards}>
          <div className={styles.smallCards}>
            {modalData?.cards?.map((cardItem, index) => (
              <div key={index} className={styles.card}>
                <Image
                  src={cardItem?.logoUrl}
                  alt={modalData?.name}
                  width={40}
                  height={40}
                  className={styles.cardImage}
                />
                <span className={styles.cardText}>{cardItem?.text}</span>
              </div>
            ))}
          </div>
          <div className={styles.bigCard}>
            <div className={styles.content}>
              <span className={styles.heading}>{modalData?.qrHeading}</span>
              <span className={styles.subHeading}>{modalData?.qrSubHeading}</span>
            </div>
            <Image src={modalData?.qrImageSrc} alt="Acko app QR" width={120} height={120} className={styles.qrImage} />
          </div>
        </div>
      </div>
      <div className={styles.modalMediaWrapper} style={{ backgroundImage: `url(${modalData?.bgBlurImageUrl})` }}>
        <div className={styles.modalMediaFrame} style={{ backgroundImage: `url(${modalData?.bgHandImageUrl})` }}>
          <div className={styles.modalMediaStyle}>
            {modalData?.hasVideo ? (
              <video className={styles.media} autoPlay loop muted playsInline>
                <source src={modalData?.mediaSrc} type="video/mp4" />
                {modalData?.name}
              </video>
            ) : (
              <Image width={500} height={500} className={styles.media} alt="Modal image" src={modalData?.mediaSrc} />
            )}
          </div>
        </div>
      </div>
    </div>
  </OverlayModal>
)
