'use client'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import PrimaryButton from '@components/Home/PrimaryButton/Button'
import OverlayModal from '@components/OverlayModal/overlayModal'
import DownloadAppModal from '@components/HomePage/DownloadAppModal/downloadAppModal'
import { ButtonType } from '@components/Home/PrimaryButton/buttonType'
import { HEADER_NAME_SEGMENT, widgetCommonSegmentProperties } from '@components/HomePage/utils/SegmentEventConstants'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import Toast from '@components/Toast/toast'
import { GET_HCAPTCHA_TOKEN_EVENT, LOAD_HCAPTCHA_EVENT } from '@constants/customEvents'
import ScrollEvent from '@components/HomePage/utils/scrollEvent'
import styles from './StackCards.module.scss'

interface IStackCard {
  id: number
  name?: string
  heading?: string
  subHeading?: string
  description?: string
  ctaText?: string
  uspImageSrc?: string
  type?: string
  modalHeading?: string
  appLink?: string
  scannerImageSrc?: string
}
interface StackCardsProps {
  homepageCards?: IStackCard[]
}

const RenderCard = ({
  heading,
  subHeading,
  description,
  uspImageURL,
  scannerImageUrl,
  ctaText,
  appLink,
  type,
  setToastText,
  setToastType,
  modalHeading,
}) => {
  const [show, setShow] = useState(false)
  const [token, setToken] = useState<string>('')

  const onCtaClick = () => {
    setShow(true)
  }
  useEffect(() => {
    if (show) {
      const hcaptchEvent = new CustomEvent(LOAD_HCAPTCHA_EVENT, {
        detail: true,
      })
      document.dispatchEvent(hcaptchEvent)
    }
  }, [show])

  useEffect(() => {
    document.addEventListener(GET_HCAPTCHA_TOKEN_EVENT, handleGetHcaptchaToken)

    return () => {
      document.removeEventListener(GET_HCAPTCHA_TOKEN_EVENT, handleGetHcaptchaToken)
    }
  }, [])
  const handleGetHcaptchaToken = (e: CustomEvent) => {
    setToken(e.detail.token)
  }

  return (
    <div key={type} className={styles.card}>
      <ScrollEvent
        elementId={type}
        eventProps={{ header_name: HEADER_NAME_SEGMENT[HomeComponentType.StackCard], journey: type }}
        eventName="wrapper_scroll_to_section"
      />
      <div id={type} className={styles.cardContent}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.ctaWeb}>
          <PrimaryButton
            text={ctaText}
            onButtonClick={onCtaClick}
            buttonType={ButtonType.WHITE}
            segmentEventName="btn_get_app_link_initiated"
            segmentEventProps={{
              header_name: HEADER_NAME_SEGMENT[HomeComponentType.StackCard],
              journey: type,
            }}
          />
          <OverlayModal show={show} setShow={setShow}>
            <DownloadAppModal
              imageUrl={scannerImageUrl}
              widgetType={HomeComponentType.StackCard}
              customJourney={type}
              setShowOverlay={setShow}
              setToastText={setToastText}
              setToastType={setToastType}
              token={token}
              setToken={setToken}
              heading={modalHeading}
            />
          </OverlayModal>
        </div>
      </div>
      {uspImageURL && (
        <div className={styles.imageContainer}>
          <Image loading="lazy" objectFit="contain" layout="fill" src={uspImageURL} alt="card-one" />
        </div>
      )}
      <div className={styles.ctaMobile}>
        <PrimaryButton
          text={ctaText}
          link={appLink}
          buttonType={ButtonType.WHITE}
          segmentEventName="btn_get_app_link"
          segmentEventProps={{
            ...widgetCommonSegmentProperties({ widgetType: HomeComponentType.StackCard, customJourney: type }),
            funnel: 'entry',
          }}
        />
      </div>
    </div>
  )
}

const StackedCards = ({ homepageCards }: StackCardsProps) => {
  const [toastText, setToastText] = useState('')
  const [toastType, setToastType] = useState('')

  return (
    <>
      <div className={styles.cardsWrapper}>
        <div id={HomeComponentType.StackCard} className={styles.cards}>
          {homepageCards?.map(cardData =>
            RenderCard({
              heading: cardData?.heading,
              subHeading: cardData?.subHeading,
              description: cardData?.description,
              uspImageURL: cardData?.uspImageSrc,
              scannerImageUrl: cardData?.scannerImageSrc,
              ctaText: cardData?.ctaText,
              appLink: cardData?.appLink,
              type: cardData?.type,
              modalHeading: cardData?.modalHeading,
              setToastText,
              setToastType,
            })
          )}
        </div>
      </div>
      <Toast isMobile={false} message={toastText} toastType={toastType} />
    </>
  )
}

export default StackedCards
