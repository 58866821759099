/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import Image from 'next/image'
import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { r2d2 } from '@utils/utils'
import { domainConfig } from '@configs/domain.config'
import { Domain } from '@enums/Domain'
import { PLATFORM } from '@configs/platform.config'
import { useState } from 'react'
import { homePageCommonProps } from '@utils/segmentProperties'
import Arrow from '@public/images/homepage-arrow.svg'
import { PRODUCT_BUTTON_EXPERIMENT_NAME } from '@constants/homeSegmentEvent'
import { ProductItem } from '@interfaces/landingWidget'
import { useEnvVariablesClientConfig } from '@context/EnvClientContext/EnvVariablesClientContext'
import { ProductModal } from './ProductModal/ProductModal'
import styles from './ProductCardButton.module.scss'

interface ProductCardButtonProps {
  product: ProductItem
  imageSize?: number
  domain?: Domain
}

export const ProductCardButton = ({ product, imageSize = 96, domain = Domain.ACKO }: ProductCardButtonProps) => {
  const [show, setShow] = useState(false)
  const config = useEnvVariablesClientConfig()
  return (
    <>
      <a
        onClick={e => {
          e.preventDefault()
          ;(async function IFFE() {
            const commonProps = homePageCommonProps()
            try {
              const trackingEvents = [
                trackEvent(domainConfig[domain].events.homePage, {
                  ...commonProps,
                  ...product?.eventProps,
                  experiment_name: PRODUCT_BUTTON_EXPERIMENT_NAME,
                  from_page: window.location.href,
                }),
                r2d2(
                  {
                    ekind: domainConfig[domain].events.homePage,
                    odata: { product: product?.productsShortName },
                  },
                  `${window.location.origin}/api/r2d2/`
                ),
              ]
              await Promise.allSettled(trackingEvents)
            } catch (e) {
              console.error('track event error')
            }
            if (product?.opensModal && commonProps.platform === PLATFORM.DESKTOP) {
              setShow(true)
              return
            }
            domainConfig[domain].onClick(
              commonProps.platform === PLATFORM.DESKTOP ? product?.desktopUrl : product?.mobileUrl,
              config?.ACKO_ENV
            )
          })()
        }}
        style={{ gridArea: `${product?.productsShortName}` }}
        className={`${styles.productCardButton} ${domainConfig[domain].style(styles)} ${
          product?.isFeatured && styles.featured
        }`}
        href={product?.desktopUrl}>
        <span className={styles.productHeading}>{product?.productName}</span>
        {product?.description && <span className={styles.productSubHeading}>{product?.description}</span>}
        {product?.label && <span className={styles.productOffer}>{product?.label}</span>}
        <Image
          src={product?.productImageSrc}
          alt={product?.productName}
          width={imageSize}
          height={imageSize}
          className={styles.productImage}
        />
        <Arrow className={styles.arrow} />
      </a>
      {product?.opensModal && <ProductModal modalData={product?.cardModal} show={show} setShow={setShow} />}
    </>
  )
}
