'use client'

import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import { When } from 'react-if'
import { ProductCardButton } from '@components/Home/ProductCardButton/ProductCardButton'
import AckoDriveProductBanner from '@components/ProductBanner/ProductBanner'
import { IBannerProps } from '@interfaces/landingWidget'
import { BannerType } from '@enums/BannerType'
import styles from './HomeLandingWidget.module.scss'
import { VARIANT } from './constants'

interface HomeLandingWidgetProps {
  banners: IBannerProps[]
}

const CURRENT_VARIANT = VARIANT.WITH_PRODUCT_CARDS

const LandingPrimaryBanner: React.FC<{ banner: IBannerProps }> = ({ banner }) => (
  <div className={styles.widgetWrapper}>
    <h1 dangerouslySetInnerHTML={{ __html: banner?.heading }} />
    {!!banner?.subHeading && <h1 className={styles.subHeading}>{banner?.subHeading}</h1>}

    <When condition={CURRENT_VARIANT === VARIANT.WITH_PRODUCT_CARDS}>
      <div className={`${styles.productCardWrappers} ${styles.extraMarginTop}`}>
        {banner?.productCards?.data?.map(productItem => (
          <ProductCardButton key={productItem?.id} product={productItem?.attributes} />
        ))}
      </div>
    </When>
  </div>
)

const HomeLandingWidget = ({ banners }: HomeLandingWidgetProps) => (
  <div
    id={HomeComponentType.LandingWidget}
    className={`${styles.widgetContainer} ${
      CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS
        ? styles.variantA
        : CURRENT_VARIANT === VARIANT.WITH_PRODUCT_CARDS
          ? styles.variantB
          : ''
    }`}>
    <div className={styles.dataContainer}>
      {banners?.map((banner, index) =>
        banner?.bannerType === BannerType.PRIMARY ? (
          <LandingPrimaryBanner key={index} banner={banner} />
        ) : (
          <AckoDriveProductBanner key={index} data={banner} />
        )
      )}
    </div>
  </div>
)

export default HomeLandingWidget
